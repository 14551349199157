import { inject, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { pipe } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import { WINDOW } from '@app/services/window.service';
import { GTAG_CONFIG } from './gtag-config';
import { GtagConfig, GtagEvent, GtagPageview } from './interfaces';

declare var gtag: any;
declare var dataLayer: any[];

@Injectable({ providedIn: 'root' })
export class Gtag implements OnDestroy {
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly gaConfig: GtagConfig = { trackPageviews: true, ...inject(GTAG_CONFIG) };
  private readonly window = inject(WINDOW);

  private readonly subscription = this.gaConfig.trackPageviews
    ? this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          this.router.url === '/' ? startWith(this.activatedRoute) : pipe(),
          map(() => getLastActivatedRoute(this.activatedRoute)),
          filter(isPrimary),
          map(({ snapshot }) => {
            const pageview = (snapshot.data.pageview as GtagPageview) ?? {};
            pageview['page_title'] ||= snapshot.title;

            return pageview;
          }),
        )
        .subscribe((pageview) => {
          this.pageview(pageview);
        })
    : undefined;

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  event(action: string, params: GtagEvent = {}) {
    // try/catch to avoid cross-platform issues
    try {
      if ((this.window as any).dataLayer) {
        dataLayer.push({ ecommerce: null });
        dataLayer.push({ event: action, ecommerce: { ...params } });
        this.debug('event', this.gaConfig.trackingId, action, params);
      }
    } catch (err) {
      console.error('Google Analytics event error', err);
    }
  }

  pageview(params?: GtagPageview) {
    try {
      const defaults = {
        page_path: this.router.url,
        page_title: 'Angular App',
        page_location: this.window.location?.href ?? '',
      };

      params = { ...defaults, ...params };
      if ((this.window as any).gtag) {
        gtag('config', this.gaConfig.trackingId, params);
        this.debug('pageview', this.gaConfig.trackingId, params);
      }
    } catch (err) {
      console.error('Google Analytics pageview error', err);
    }
  }

  set(params: any) {
    try {
      if ((this.window as any).gtag) {
        gtag('set', (params = {}));
      }
    } catch (err) {
      console.error('Google Analytics set error', err);
    }
  }

  private debug(...msg: any[]) {
    if (this.gaConfig.debug) {
      console.log('🏷️ gtag:', ...msg);
    }
  }
}

function getLastActivatedRoute(route: ActivatedRoute): ActivatedRoute {
  while (route.firstChild) {
    route = route.firstChild;
  }

  return route;
}

function isPrimary(routeData: Data): boolean {
  const { outlet } = routeData;
  return outlet === 'primary';
}
