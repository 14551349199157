import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Department } from '@app/core/entities/department';
import { OrderProduct } from '@app/core/entities/order.interface';
import { Product } from '@app/core/entities/product.interface';
import { Gtag } from '@app/utils/gtag/gtag.service';

interface UserInfo {
  customer_id?: string | null;
  email?: string | null;
}

function dataFromRoute() {
  const router = inject(ActivatedRoute);

  return () => {
    return getDataFromRoute(router.snapshot);
  };
}

@Injectable({ providedIn: 'root' })
export class ReportAnalyticsService {
  private readonly gtag = inject(Gtag);
  private readonly data = dataFromRoute();

  /**
   * [select_item](https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#select_item)
   */
  select_item(products: Product[]): void {
    this.gtag.event('select_item', {
      page_type: this.data().title,
      ecommerce: {
        currency: 'ILS',
      },
      items: getItems(products),
      ...getDepartments(products),
    });
  }

  /**
   * [view_item](https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_item)
   */
  view_item(products: Product[]) {
    this.gtag.event('view_item', {
      page_type: this.data().title,
      currency: 'ILS',
      items: getItems(products),
      ...getDepartments(products),
    });
  }

  /**
   * [view_item_list](https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_item_list)
   */
  view_item_list(products: Product[]): void {
    this.gtag.event('view_item_list', {
      page_type: this.data().title,
      currency: 'ILS',
      items: getItems(products),
      ...getDepartments(products),
    });
  }

  /**
   * [add_to_cart](https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#add_to_cart)
   */
  add_to_cart(products: (Product | OrderProduct)[]) {
    this.gtag.event('add_to_cart', {
      page_type: this.data().title,
      currency: 'ILS',
      items: getItems(products),
      ...getDepartments(products),
    });
  }

  /**
   * [remove_from_cart](https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#remove_from_cart)
   */
  remove_from_cart(products: (Product | OrderProduct)[]) {
    this.gtag.event('remove_from_cart', {
      page_type: this.data().title,
      currency: 'ILS',
      items: getItems(products),
      ...getDepartments(products),
    });
  }

  /**
   * [begin_checkout](https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#begin_checkout)
   */
  async begin_checkout(user: UserInfo, products: (Product | OrderProduct)[]) {
    this.gtag.event('begin_checkout', {
      page_type: this.data().title,
      ...user,
      currency: 'ILS',
      items: getItems(products),
      ...getDepartments(products),
    });
  }

  /**
   * [add_shipping_info](https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#add_shipping_info)
   */
  async add_shipping_info(user: UserInfo, shipping: string | undefined, products: (Product | OrderProduct)[]) {
    this.gtag.event('add_shipping_info', {
      page_type: this.data().title,
      ...user,
      currency: 'ILS',
      shipping_tier: shipping,
      coupon: null,
      items: getItems(products),
      ...getDepartments(products),
    });
  }

  /**
   * [add_payment_info](https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#add_payment_info)
   */
  async add_payment_info(user: UserInfo, products: (Product | OrderProduct)[]) {
    this.gtag.event('add_payment_info', {
      page_type: this.data().title,
      ...user,
      currency: 'ILS',
      shipping: null,
      payment_type: null,
      coupon: null,
      items: getItems(products),
      ...getDepartments(products),
    });
  }

  /**
   * [purchase](https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#purchase)
   */
  async purchase(
    user: UserInfo,
    shipping: string | undefined,
    value: number,
    tax: number,
    transaction_id: string,
    products: (Product | OrderProduct)[],
  ) {
    this.gtag.event('purchase', {
      page_type: this.data().title,
      ...user,
      transaction_id: transaction_id,
      currency: 'ILS',
      tax: value * 0.17,
      shipping_tier: shipping,
      payment_type: 'Credit Card',
      value: value,
      coupon: null,
      items: getItems(products),
      ...getDepartments(products),
    });
  }

  /**
   * custom event
   */
  async insert_coupon(user: UserInfo, products: (Product | OrderProduct)[]) {
    this.gtag.event('insert_coupon', {
      page_type: this.data().title,
      ...user,
      currency: 'ILS',
      items: getItems(products),
      ...getDepartments(products),
    });
  }

  /**
   * [login](https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#login)
   */
  async login(user: UserInfo, method = '') {
    this.gtag.event('login', {
      page_type: this.data().title,
      ...user,
      method,
    });
  }

  /**
   * [sign_up](https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#sign_up)
   */
  async sign_up(userinfo: UserInfo) {
    this.gtag.event('sign_up', {
      page_type: this.data().title,
      ...userinfo,
      method: null,
    });
  }

  /**
   * custom event
   */
  page_error(event_context: string = '') {
    this.gtag.event('page_error', {
      page_type: this.data().title,
      event_context,
    });
  }

  /**
   * custom event
   */
  menu_click(event_context: string = '') {
    this.gtag.event('menu_click', {
      page_type: this.data().title,
      event_context,
    });
  }

  /**
   * [search](https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#search)
   */
  search(query: string) {
    this.gtag.event('search', {
      page_type: this.data().title,
      search_term: query,
    });
  }

  /**
   * [add_to_wishlist](https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#add_to_wishlist)
   */
  add_to_wishlist() {
    // TODO
  }

  /**
   * [refund](https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#refund)
   */
  refund() {
    // TODO
  }

  /**
   * [select_promotion](https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#select_promotion)
   */
  select_promotion() {
    // TODO
  }

  /**
   * [view_cart](https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_cart)
   */
  view_cart() {
    // TODO
  }

  /**
   * [view_promotion](https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_promotion)
   */
  view_promotion() {
    // TODO
  }
}

export const useReportAnalytics = () => inject(ReportAnalyticsService);

const getItem = (p: Product | OrderProduct) => ({
  item_name: p.name,
  item_id: p.id,
  price: p.price,
  quantity: p.quantity,
  coupon: null,
  item_brand: null,
  ...getItemCategores(p.departments),
  item_variant: null,
});

const getItems = (products: (Product | OrderProduct)[]) =>
  products.map((p, i) => ({
    ...getItem(p),
    item_list_index: i,
    ...getDepartments(products),
  }));

/**
 * @param departments
 * ```
 * ['Apparel', 'Adult', 'Shirts' ...]
 * ```
 * @returns
 * ```
 * {
 *   item_category: 'Apparel',
 *   item_category2: 'Adult'
 *   item_category3: 'Shirts',
 *   ...
 * }
 * ```
 */
const getItemCategores = (departments: Department[] | undefined) => {
  const itemCategores: any = {};
  const key = 'item_category';

  if (departments) {
    for (let i = 0; i < departments.length; i++) {
      let item = departments[i].name;

      let index: string = `${i + 1}`;
      index = index === '1' ? '' : index;

      itemCategores[`${key}${index}`] = item;
    }
  }

  return itemCategores;
};

function getDataFromRoute(route: ActivatedRouteSnapshot): any {
  if (route.firstChild) {
    return getDataFromRoute(route.firstChild);
  } else {
    return route.data;
  }
}

function getDepartments(products: (Product | OrderProduct)[]) {
  const departments = products.map((product) => product.departments).flat();

  const name = [...new Set(departments.map((department) => department?.name))].join(',');
  const id = [...new Set(departments.map((department) => department?.id))].join(',');

  return {
    item_list_name: name,
    item_list_id: id,
  };
}
